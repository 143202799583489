( function ( $ ) {

    let $header;

    function check_scroll_top () {
        $header.toggleClass( 'opaque', ( $(window).scrollTop() != 0 ) || $('.mobile-menu-toggle').is(':visible') );
    }
    
    $(window).on( 'scroll resize', check_scroll_top );
    $(function () {
        $header = $( 'header.header' );
        $(window).resize();
    });

})( jQuery );